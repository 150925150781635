<template>
  <section :class="['email-sign-up-wrapper', { 'black-bg': isBlackBg }]" :style="backgroundColorStyle">
    <div class="email-sign-up-content flex-inline desk-hor-mob-col content-wrapper">
      <div class="email-offer-header">
        <span class="h3">{{ title }}</span>
        <p>{{ offer_info }}</p>
      </div>
      <div class="email-input flex-inline desk-hor-mob-col">
        <Input
          :not-valid-override="!!state.error"
          type="email"
          name="email"
          :placeholder="placeholder"
          emit-input="text-input"
          @text-input="update($event)"
        >
          <template #errorMessage>
            {{ state.error }}
          </template>
        </Input>
        <CustomButton
          :disabled="submitSuccess"
          :aria-label="`Submit ${useTranslate('GLOBALS.emailAddress')}`"
          :is-small="smallButton"
          @click-button="submit()"
        >
          {{ submitSuccess ? 'Thank you for signing up!' : button_text || 'Sign Up' }}
        </CustomButton>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { emailValid as utilEmailValid } from '~/util/string';
const { locale }: any = useI18n();

/* eslint-disable vue/prop-name-casing */
const props = defineProps({
  // eslint-disable-next-line vue/require-default-prop
  background_color: {
    type: Object as () => {
      color: string;
    },
    required: false,
  },
  button_text: {
    type: String,
    default: '',
  },
  offer_info: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: 'Enter your email',
  },
  smallButton: {
    type: Boolean,
    default: false,
  },
});

const state = reactive({
  email: '',
  res: null as any,
  hasBeenSubmitted: false,
  error: '' as string,
});

const backgroundColorStyle = computed<string | undefined>(() => {
  const color = props.background_color?.color;
  return color || '#f3f3f3';
});
const isBlackBg = computed<boolean>(() => {
  return props.background_color?.color === '#1a1a1a' || props.background_color?.color === '#000000';
});
const submitSuccess = computed<boolean>(() => {
  return !!(state.hasBeenSubmitted && !state.error);
});
const emailValid = computed<boolean>(() => {
  return utilEmailValid(state.email);
});

async function submit(): Promise<void> {
  if (!emailValid.value) {
    state.error = 'Invalid Email';
    return;
  }
  state.error = '';
  try {
    const { regionHeaderName, regionLocaleProperties } = useRegionRequestSettings();
    const { regionCode } = regionLocaleProperties.value;
    const headers = {
      [regionHeaderName]: regionCode,
    };
    const options = {
      email: state.email,
      locale: locale.value,
    };
    const res = await $fetch('/api/cordial/subscribe', {
      method: 'POST',
      headers,
      body: JSON.stringify(options as any),
    });
    state.res = res;
  } catch (err) {
    state.error = 'Error Signing Up';
    console.error('SUB ERROR', err); // eslint-disable-line no-console
  } finally {
    if (state.res.data?.length) state.error = 'Already Subscribed';
    state.hasBeenSubmitted = true;
  }
}
function update(ev: any): void {
  state.res = null;
  state.email = ev.value;
}
</script>

<style lang="scss" scoped>
.email-sign-up-wrapper {
  padding: #{local-functions.rem-calc(56 0)};
  margin-top: 1rem;
  width: 100%;
  background-color: v-bind(backgroundColorStyle);
  &.black-bg {
    color: $color-neutral-white;
  }
  .btn {
    white-space: nowrap;
  }
  @include local-mixins.desktop {
    justify-content: space-between;
    .email-offer-header {
      max-width: 50%;
      width: 50%;
      margin-right: 1rem;
    }
    .email-input {
      width: 50%;
      justify-content: flex-end;
      align-items: flex-start;
      margin-left: 1rem;
      .input-component-container {
        margin-right: 1rem;
        width: 100%;
      }
    }
    .email-sign-up-content {
      padding: 0 1rem;
    }
  }
  @include local-mixins.tablet_and_mobile {
    .email-offer-header {
      width: 100%;
      text-align: left;
    }
    .email-input {
      width: 100%;
      .input-component-container {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }
}
</style>
